import { hot } from 'react-hot-loader/root'
import React, { lazy } from 'react'
import { Container, Image } from 'semantic-ui-react'
import { css, StyleSheet } from 'aphrodite'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { flatten, isEmpty, pick } from 'lodash'
import request from 'superagent'
import moment from 'moment'
import qs from 'qs'
import { LionessProvider, T } from 'lioness'
import { addLocaleData } from 'react-intl'
import ReactGA from 'react-ga'
import ReactGA_4 from 'react-ga4'
import TagManager from 'react-gtm-module'
import ReactPixel from 'react-facebook-pixel'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import applyTheme from '../client/styles/applyTheme'
import { hashCode } from 'hashcode'

import messages from '../translations/messages.json'

import Home from '../containers/Home'
import Error from '../components/Error'
import StepIndicator, { PageStep } from './StepIndicator'
import Maintenance from './Maintenance'
import Loader from './Loader'
import { scrollToHeight } from '../utils/scrollToHeight'
import { getPathUrl } from '../utils/url'
import { fbTrack } from '../utils/tracking'
import * as Sentry from '@sentry/react'
import PaidBookingComplete from '../containers/PaidBookingComplete'
import BookingPayment from '../containers/BookingPayment'
import Demo from '../containers/Demo'
import DevPanel from '../components/DevPanel/DevPanel'
import { isGa4 } from '../utils/googleAnalyticsVersion'
import I18NProvider from '../containers/I18nProvider'
import { clinicUuidsWithCaptcha, environmentsWithCaptcha } from '../spamProtectionConfig'

const AppointmentType = lazy(() => import('../containers/AppointmentType'))
const Treatment = lazy(() => import('../containers/Treatment'))
const ClinicLocation = lazy(() => import('../containers/ClinicLocation'))
const DateTime = lazy(() => import('../containers/DateTime'))
const PersonalDetails = lazy(() => import('../containers/PersonalDetails'))
const AppointmentConfirmation = lazy(() => import('../containers/AppointmentConfirmation'))
const BookingComplete = lazy(() => import('../containers/BookingComplete'))
const CancelAppointment = lazy(() => import('../containers/CancelAppointment'))
const CancellationComplete = lazy(() => import('../containers/CancellationComplete'))
const ReschedulingComplete = lazy(() => import('../containers/ReschedulingComplete'))

if (window.ReactIntlLocaleData) {
	addLocaleData(flatten(Object.values(window.ReactIntlLocaleData)))
}

const appStyles = StyleSheet.create({
	logo: {
		marginBottom: '30px',
		maxHeight: 150,
	},
	branding: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	appContainer: {
		paddingTop: '30px',
		paddingBottom: '60px',
		// Clearfix
		'::after': {
			content: '""',
			display: 'block',
			clear: 'both',
		},
	},
})

// Used by when the back button is pressed to reset the state to the previous page
// TODO: This should have been an array. That way we could reset multiple screens when calling go(-2).
const screenReset = (pathname, locationQueryParam) =>
	({
		'/': {
			serviceId: null,
		},
		'/appointment-types': {
			appointmentType: null,
		},
		'/treatments': {
			treatmentId: null,
		},
		'/prepayment': {
			prepaymentUuid: null,
		},
		'/locations': {
			locationUuid: locationQueryParam || null,
			doctorId: null,
		},
		'/availability': {
			locationUuid: locationQueryParam || null,
			start: null,
		},
	}[pathname])

const DEFAULT_PATH = '/services'

// This component holds all the data eventually sent to the backend
class App extends React.Component {
	constructor(props) {
		super(props)
		const query = qs.parse(props.query)
		this.initialState = {
			clinicUuid: query.clinic || null,
			prepaymentUuid: query.prepayment || null,
			locationUuid: query.location || null,
			demoComponent: props.path === '/demo',
			website: null,
			locale: query.l,
			serviceId: null,
			selectedServiceUuid: null,
			serviceUuid: query.service || null,
			doctorId: null,
			appointmentType: null,
			notBookableAppointmentTypeName: '',
			notBookableAppointmentTypeExplanation: '',
			treatmentId: null,
			start: null,
			sex: '',
			initials: '',
			firstName: '',
			lastName: '',
			dateOfBirth: null,
			email: '',
			mobile: '',
			mobileCountry: '',
			note: '',
			discountCode: '',
			personalDetailsFormState: null,
			logoExists: null,
			hideLogo: 'hide-logo' in query,
			cancellationToken: query.appointment,
			step: null,
			requireTreatment: false,
			requireLocation: !Boolean(query.location),
			allowOtherServices: query.hasOwnProperty('allow-other-services'),
			gaId: null,
			anonymizeIp: null,
			openEventSent: false,
			newsletter: false,
			prepaymentRequired: null,
			prepaymentAmount: null,
			prepaymentUrl: null,
			backendAvailable: null,
			analyticsAvailable: false,
			facebookPixelAvailable: false,
			tagManagerPixelAvailable: false,
			refundAmount: null,
			refundOption: null,
			appointmentToken: null,
			userUuid: query.user || null,
			isGa4: false,
			intlLocale: null,
			locationQueryParam: query.location, // do not change this value
			queryParams: this.props.location.search,
			showErrorPage: false,
		}
		this.state = { ...this.initialState }

		// Check if Clinicminds backend is available
		request
			.head('/api/ping')
			.then(res => {
				this.setState({ backendAvailable: res.status === 204 })
			})
			.catch(() => {
				this.setState({ backendAvailable: false })
			})

		// Only check for logo if request is valid
		if (this.state.clinicUuid || this.state.locationUuid) {
			if (!this.state.hideLogo) {
				request
					.head(
						`/api/images/logo?${
							// Needed because apperenty sometimes App rerenders when both values are already set.
							// Location takes precedence when both are specified in the URL
							query.location ? `location=${this.state.locationUuid}` : `clinic=${this.state.clinicUuid}`
						}`
					)
					.ok(res => res.status < 500)
					.then(res => {
						this.setState({ logoExists: res.status === 200 })
					})
					.catch(reason => {
						this.setState({ logoExists: false })
						if (!reason.status) {
							return
						}
						Sentry.captureException(new Error(`/api/images/logo responded with status code: ${reason.status}`))
					})
			}

			request
				.get(
					`/api/clinic-info?${
						this.state.clinicUuid ? `clinic=${this.state.clinicUuid}` : `location=${this.state.locationUuid}`
					}`
				)
				.then(({ body }) => {
					this.setState({
						website: body.website,
					})
				})

			request
				.get(
					`/api/a7s?${
						this.state.clinicUuid ? `clinic=${this.state.clinicUuid}` : `location=${this.state.locationUuid}`
					}`
				)
				.then(({ body }) => {
					// ReactGA loads the library on initialize.
					if (body.gaId) {
						const initGaOptions = {
							titleCase: false,
							gaOptions: {
								cookieDomain: document.location.hostname,
								cookieFlags: 'SameSite=None; Secure',
								siteSpeedSampleRate: 0,
								allowLinker: true,
								allowAnchor: false,
							},
							gtagOptions: {
								send_page_view: false,
							},
						}
						const gclid = query.gclid
						const gaLocation = getPathUrl(this.getHomeRoute()) + (gclid ? `?gclid=${gclid}` : '')

						if (isGa4(body.gaId)) {
							const hasGa4LinkerParam = !!query._gl
							const hasCmLinkerParam = !!query._cm_gtag && query._cm_gtag.split('*').length === 4

							if (hasCmLinkerParam && !hasGa4LinkerParam) {
								const [cmGtagCid, cmGtagSid, cmGtagUa, cmGtagTs] = query._cm_gtag.split('*')

								const paramMaxLifetime = 5 * 60 * 1000 // 5 min in ms
								const isTsValid = parseInt(cmGtagTs) > Date.now() - paramMaxLifetime
								const isUaValid = cmGtagUa === hashCode().value(window.navigator.userAgent).toString()

								if (!isUaValid) {
									console.log(
										"[Clinicminds-Google Analytics] User-Agent hash doesn't match; not applying Cross-Domain Measurement"
									)
								} else if (!isTsValid) {
									console.log(
										'[Clinicminds-Google Analytics] Parameters expired; not applying Cross-Domain Measurement'
									)
								} else {
									initGaOptions.gtagOptions = {
										client_id: cmGtagCid,
										session_id: cmGtagSid,
									}
								}
							}
							ReactGA_4.initialize(body.gaId, initGaOptions)
							ReactGA_4.set({ anonymize_ip: body.anonymizeIp })
							ReactGA_4.set({ language: this.state.locale })
							ReactGA_4.set({ page_location: gaLocation })
							ReactGA_4.set({
								campaign_source: query.utm_source,
								campaign_medium: query.utm_medium,
								campaign_name: query.utm_campaign,
								campaign_term: query.utm_term,
								campaign_content: query.utm_content,
							})
							ReactGA_4.send('pageview')
						} else {
							ReactGA.initialize(body.gaId, initGaOptions)
							ReactGA.set({ anonymizeIp: body.anonymizeIp })
							ReactGA.set({ language: this.state.locale })
							ReactGA.set({ location: gaLocation })
							ReactGA.set({
								campaignSource: query.utm_source,
								campaignMedium: query.utm_medium,
								campaignName: query.utm_campaign,
								campaignKeyword: query.utm_term,
								campaignContent: query.utm_content,
							})
							ReactGA.send('pageview')
						}
					}

					if (body.tagManagerId) {
						TagManager.initialize({
							gtmId: body.tagManagerId,
						})
					}

					if (body.facebookPixelId) {
						const advancedMatching = undefined
						const options = {
							// Disable automatic tracking of button clicks and page metadata
							// Because these happen automatically, we cannot override the page URL to remove the query
							// params (like clinic UUID), which get classified as "potentially violating health data"
							// https://developers.facebook.com/docs/meta-pixel/advanced#automatic-configuration
							autoConfig: false,
						}
						ReactPixel.init(body.facebookPixelId, advancedMatching, options)
						window.fbq.disablePushState = true
						fbTrack(() => {
							window.fbq.allowDuplicatePageViews = true
							ReactPixel.pageView()
						})
					}

					this.setState({
						gaId: body.gaId,
						anonymizeIp: body.anonymizeIp,
						analyticsAvailable: !!body.gaId,
						facebookPixelAvailable: !!body.facebookPixelId,
						tagManagerPixelAvailable: !!body.tagManagerId,
						tagManagerId: body.tagManagerId,
						isGa4: isGa4(body.gaId),
					})
				})
				.catch(e => console.log('/api/a7s is fail or blocked', e))
		}
	}

	UNSAFE_componentWillReceiveProps({ location, history: { action } }) {
		// When the back button is pressed, reset the state to the previous page
		if (location.pathname !== this.props.location.pathname && action === 'POP') {
			const resetObject = screenReset(this.props.location.pathname, this.state.locationQueryParam)
			if (!isEmpty(resetObject)) {
				this.setState({
					...resetObject,
				})
			}
		}
	}

	loadReCaptchaScript = () => {
		const script = document.createElement('script')
		script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.RECAPTCHA_KEY}`
		document.body.appendChild(script)
	}

	// load reCAPTCHA if there is a clinic uuid in the url
	componentDidMount() {
		if (
			this.state.clinicUuid &&
			(environmentsWithCaptcha.includes(process.env.ENVIRONMENT) ||
				clinicUuidsWithCaptcha.includes(this.state.clinicUuid))
		) {
			this.loadReCaptchaScript()
		}

		// if the url is different from DEFAULT_PATH then redirect
		// e.g. on page refresh
		if (!this.isDefaultPath(this.props.location)) {
			this.redirectToDefaultPath()
		}
	}

	componentDidUpdate(prevProps, prevState) {
		//applyTheme only once - when data is loaded from API
		if (this.props.data.location && !prevProps.data.location) {
			applyTheme(this.props.data.location.clinic)
		} else if (this.props.data.clinic && !prevProps.data.clinic) {
			applyTheme(this.props.data.clinic)
		}

		// load reCAPTCHA if there is a location uuid in the url instead of clinic uuid
		if (this.props.data.location?.clinic && !prevProps.data.location?.clinic) {
			const clinicUuid = this.props.data.location.clinic.uuid
			if (environmentsWithCaptcha.includes(process.env.ENVIRONMENT) || clinicUuidsWithCaptcha.includes(clinicUuid)) {
				this.loadReCaptchaScript()
			}
		}

		const { location } = this.props
		const locationChanged = location.pathname !== prevProps.location.pathname
		const isNotRootPath = prevProps.location.pathname !== '/'

		if (locationChanged && isNotRootPath) {
			scrollToHeight(0)
			// handle Google Analytics
			if (this.state.analyticsAvailable) {
				const gaLocation = getPathUrl(location.pathname)
				if (this.state.isGa4) {
					ReactGA_4.set({ page_location: gaLocation })
					ReactGA_4.send('pageview')
				} else {
					ReactGA.set({ location: gaLocation })
					ReactGA.send('pageview')
				}
			}
			// handle facebook analytics
			if (this.state.facebookPixelAvailable) {
				fbTrack(() => ReactPixel.pageView(), location.pathname)
			}
		}

		// handle intlLocale
		if (!this.props.data.loading && !prevState.intlLocale) {
			const locales = this.props.data.location
				? this.props.data.location.clinic?.locales
				: this.props.data.clinic?.locales
			const intlLocale =
				locales?.find(locale => locale.locale === this.state.locale)?.intlLocale ?? this.state.locale ?? 'en-GB'

			if (intlLocale !== prevState.intlLocale) {
				this.setState({ intlLocale })
			}
		}
	}

	resetAppState = () => {
		// Lazy but fool-proof solution
		document.location.reload(true)
	}

	handleStepChange = step => {
		const currentStep = this.state.step
		if (step && !currentStep && ![PageStep.BOOKED, PageStep.HOME].includes(step)) {
			return
		}
		this.setState({ step })
	}
	handleRequireTreatmentChange = requireTreatment => this.setState({ requireTreatment })
	handleServiceChange = serviceId => this.setState({ serviceId })
	handleSelectedServiceChange = selectedServiceUuid => {
		this.setState({ serviceUuid: null, serviceId: null, selectedServiceUuid })
	}
	handleClinicChange = clinicUuid => this.setState({ clinicUuid })
	handleLocationChange = locationUuid => this.setState({ locationUuid })
	handleAppointmentTypeChange = appointmentType => this.setState({ appointmentType })
	handleNotBookableAppointmentTypeChange = (appointmentType, name, explanation) =>
		this.setState({
			appointmentType,
			notBookableAppointmentTypeName: name,
			notBookableAppointmentTypeExplanation: explanation,
		})
	handleTreatmentChange = treatmentId => this.setState({ treatmentId })
	handleDoctorChange = doctorId => this.setState({ doctorId })
	handleTimeChange = time => this.setState({ start: moment.utc(time.start) })
	handlePersonalDetailsChange = personalDetails => this.setState(personalDetails)
	handleMobileChange = mobile => this.setState({ mobile })
	handleNewsletterChange = newsletter => this.setState({ newsletter })
	handleRescheduleDetailsChange = (serviceId, appointmentType, locationUuid) =>
		this.setState({
			serviceId,
			appointmentType,
			locationUuid,
		})
	handleOpenEventSent = () => this.setState({ openEventSent: true })
	handleRefundDataChange = (refundOption, refundAmount) => this.setState({ refundOption, refundAmount })
	handlePrepaymentAmountChange = prepaymentAmount => this.setState({ prepaymentAmount })
	handleAppointmentTokenChange = appointmentToken => this.setState({ appointmentToken })

	savePersonalDetailsFormState = personalDetailsFormState => this.setState({ personalDetailsFormState })
	setBookedState = ({ locationUuid, serviceId, appointmentType }) =>
		this.setState({ locationUuid, serviceId, appointmentType })

	getHomeRoute = () => {
		if (this.state.cancellationToken) {
			return '/appointment'
		} else if (this.state.prepaymentUuid) {
			return '/prepayment'
		} else if (this.state.demoComponent) {
			return '/demo'
		}
		return DEFAULT_PATH
	}

	pushWithPreservedQuery = path => {
		const currentSearch = this.props.location.search
		this.props.history.push({
			pathname: path,
			search: currentSearch,
		})
	}

	replaceWithPreservedQuery = path => {
		const currentSearch = this.props.location.search
		this.props.history.replace({
			pathname: path,
			search: currentSearch,
		})
	}

	isDefaultPath(location) {
		// Check if the current path is root or a specific default path
		return location.pathname.startsWith(DEFAULT_PATH)
	}

	redirectToDefaultPath = () => {
		// Redirect to the default path
		this.replaceWithPreservedQuery(this.getHomeRoute())
	}

	showErrorPage = () => this.setState({ showErrorPage: true })

	renderApp() {
		const query = qs.parse(this.props.query)
		const isWrongUserInUrl = query.user && !this.props.data.user
		const isWrongLocationInUrl = query.location && !this.props.data.location
		const isWrongClinicInUrl = query.clinic && !this.props.data.clinic

		if (isWrongUserInUrl || isWrongLocationInUrl || isWrongClinicInUrl || this.state.showErrorPage) {
			return <Error />
		}

		// If backend not available, show maintenance page
		if (!this.state.backendAvailable) {
			if (
				this.state.backendAvailable === null ||
				this.state.logoExists === null ||
				this.state.analyticsAvailable === null
			) {
				return (
					<div style={{ paddingTop: 30 }}>
						<Loader />
					</div>
				)
			}
			return <Maintenance />
		}

		if (this.props.data.loading) {
			return <Loader />
		}

		if (!this.props.data.location && !this.props.data.clinic) {
			return <Error />
		}

		if (
			!this.props.data.location?.clinic?.onlineSchedulingEnabled &&
			!this.props.data.clinic?.onlineSchedulingEnabled
		) {
			return (
				<Error
					description={
						<T>Online bookings are currently not possible. Please contact the clinic to book an appointment.</T>
					}
				/>
			)
		}

		return (
			<Switch>
				<Route
					path="/demo"
					render={({ history }) => (
						<Demo
							clinicUuid={this.state.clinicUuid}
							website={this.state.website}
							history={history}
							resetAppState={this.resetAppState}
						/>
					)}
				/>
				<Route path="*">
					{/* Would be better to wrap pages in Page components that could control
					which step they are because this is not maintainable*/}
					<Container className={css(appStyles.appContainer)}>
						{this.state.logoExists && !this.state.hideLogo && (
							<Container textAlign="center" className={css(appStyles.branding)}>
								{this.state.website ? (
									<a href={this.state.website} target="_top">
										<Image
											src={`/api/images/logo?${
												qs.parse(this.props.query).location
													? `location=${this.state.locationUuid}`
													: `clinic=${this.state.clinicUuid}`
											}`}
											className={css(appStyles.logo)}
										/>
									</a>
								) : (
									<Image
										src={`/api/images/logo?${
											qs.parse(this.props.query).location
												? `location=${this.state.locationUuid}`
												: `clinic=${this.state.clinicUuid}`
										}`}
										className={css(appStyles.logo)}
									/>
								)}
							</Container>
						)}
						<Container>
							{this.state.step && (
								<StepIndicator
									step={this.state.step}
									requireTreatment={this.state.requireTreatment}
									requireLocation={this.state.requireLocation}
								/>
							)}
							<Switch>
								<Route
									path={DEFAULT_PATH}
									render={({ history }) => (
										<Home
											locationUuid={this.state.locationUuid}
											clinicUuid={this.state.clinicUuid}
											serviceId={this.state.serviceId}
											selectedServiceUuid={this.state.selectedServiceUuid}
											serviceUuid={this.state.serviceUuid}
											allowOtherServices={this.state.allowOtherServices}
											openEventSent={this.state.openEventSent}
											step={this.state.step}
											onStepChange={this.handleStepChange}
											onServiceChange={this.handleServiceChange}
											onSelectedServiceChange={this.handleSelectedServiceChange}
											onClinicChange={this.handleClinicChange}
											onLocationChange={this.handleLocationChange}
											onOpenEventSent={this.handleOpenEventSent}
											isRescheduling={!!this.state.cancellationToken}
											analyticsAvailable={this.state.analyticsAvailable}
											isGa4={this.state.isGa4}
											facebookPixelAvailable={this.state.facebookPixelAvailable}
											tagManagerPixelAvailable={this.state.tagManagerPixelAvailable}
											history={history}
											userId={this.props?.data?.user?.id}
											onDoctorChange={this.handleDoctorChange}
											locale={this.state.locale}
											pushWithPreservedQuery={this.pushWithPreservedQuery}
											showErrorPage={this.showErrorPage}
										/>
									)}
								/>
								<Route
									path="/appointment"
									render={({ history }) => (
										<CancelAppointment
											clinicUuid={this.state.clinicUuid}
											cancellationToken={this.state.cancellationToken}
											onRescheduleDetailsChange={this.handleRescheduleDetailsChange}
											onRefundDataChange={this.handleRefundDataChange}
											onPrepaymentAmountChange={this.handlePrepaymentAmountChange}
											onAppointmentTokenChange={this.handleAppointmentTokenChange}
											locale={this.state.locale}
											history={history}
											pushWithPreservedQuery={this.pushWithPreservedQuery}
										/>
									)}
								/>
								<Route
									path="/appointment-types"
									render={({ history }) => (
										<AppointmentType
											locationUuid={this.state.locationUuid}
											clinicUuid={this.state.clinicUuid}
											serviceId={this.state.serviceId}
											appointmentType={this.state.appointmentType}
											onStepChange={this.handleStepChange}
											onAppointmentTypeChange={this.handleAppointmentTypeChange}
											analyticsAvailable={this.state.analyticsAvailable}
											isGa4={this.state.isGa4}
											facebookPixelAvailable={this.state.facebookPixelAvailable}
											tagManagerPixelAvailable={this.state.tagManagerPixelAvailable}
											history={history}
											resetAppState={this.resetAppState}
											userId={this.props?.data?.user?.id}
											onNotBookableAppointmentTypeChange={this.handleNotBookableAppointmentTypeChange}
											pushWithPreservedQuery={this.pushWithPreservedQuery}
											replaceWithPreservedQuery={this.replaceWithPreservedQuery}
										/>
									)}
								/>
								<Route
									path="/treatments"
									render={({ history }) => (
										<Treatment
											locationUuid={this.state.locationUuid}
											clinicUuid={this.state.clinicUuid}
											serviceId={this.state.serviceId}
											treatmentId={this.state.treatmentId}
											onStepChange={this.handleStepChange}
											onTreatmentChange={this.handleTreatmentChange}
											onRequireTreatmentChange={this.handleRequireTreatmentChange}
											history={history}
											resetAppState={this.resetAppState}
											pushWithPreservedQuery={this.pushWithPreservedQuery}
										/>
									)}
								/>
								<Route
									path="/locations"
									render={({ history }) => (
										<ClinicLocation
											serviceId={this.state.serviceId}
											appointmentType={this.state.appointmentType}
											clinicUuid={this.state.clinicUuid}
											doctorId={this.state.doctorId}
											locationUuid={this.state.locationUuid}
											onStepChange={this.handleStepChange}
											onDoctorChange={this.handleDoctorChange}
											onLocationChange={this.handleLocationChange}
											analyticsAvailable={this.state.analyticsAvailable}
											isGa4={this.state.isGa4}
											facebookPixelAvailable={this.state.facebookPixelAvailable}
											tagManagerPixelAvailable={this.state.tagManagerPixelAvailable}
											history={history}
											resetAppState={this.resetAppState}
											userId={this.props?.data?.user?.id}
											notBookableAppointmentTypeName={this.state.notBookableAppointmentTypeName}
											notBookableAppointmentTypeExplanation={this.state.notBookableAppointmentTypeExplanation}
											isRescheduling={!!this.state.cancellationToken}
											locationSpecifiedInUrl={this.state.locationQueryParam}
											doctorSpecifiedInUrl={this.state.userUuid}
											pushWithPreservedQuery={this.pushWithPreservedQuery}
											replaceWithPreservedQuery={this.replaceWithPreservedQuery}
											redirectToDefaultPath={this.redirectToDefaultPath}
										/>
									)}
								/>
								<Route
									path="/availability"
									render={({ history }) => (
										<DateTime
											locale={this.state.locale}
											clinicUuid={this.state.clinicUuid}
											serviceId={this.state.serviceId}
											treatmentId={this.state.treatmentId}
											appointmentType={this.state.appointmentType}
											doctorId={this.state.doctorId}
											locationUuid={this.state.locationUuid}
											onStepChange={this.handleStepChange}
											onTimeChange={this.handleTimeChange}
											cancellationToken={this.state.cancellationToken}
											isRescheduling={!!this.state.cancellationToken}
											onDoctorChange={this.handleDoctorChange}
											onLocationChange={this.handleLocationChange}
											history={history}
											resetAppState={this.resetAppState}
											userId={this.props?.data?.user?.id}
											locationQueryParam={this.state.locationQueryParam}
											pushWithPreservedQuery={this.pushWithPreservedQuery}
											redirectToDefaultPath={this.redirectToDefaultPath}
										/>
									)}
								/>
								<Route
									path="/personal-details"
									render={({ history }) => (
										<PersonalDetails
											// Do we need to nest this?
											formData={pick(this.state, [
												'sex',
												'initials',
												'firstName',
												'lastName',
												'dateOfBirth',
												'email',
												'mobile',
												'newsletter',
												'note',
												'discountCode',
											])}
											formState={this.state.personalDetailsFormState}
											clinicUuid={this.state.clinicUuid}
											locationUuid={this.state.locationUuid}
											onStepChange={this.handleStepChange}
											onFormChange={this.handlePersonalDetailsChange}
											onNewsletterChange={this.handleNewsletterChange}
											saveFormState={this.savePersonalDetailsFormState}
											history={history}
											resetAppState={this.resetAppState}
											intlLocale={this.state.intlLocale}
											pushWithPreservedQuery={this.pushWithPreservedQuery}
											redirectToDefaultPath={this.redirectToDefaultPath}
										/>
									)}
								/>
								<Route
									path="/verification"
									render={({ history }) => (
										<AppointmentConfirmation
											{...this.state}
											onStepChange={this.handleStepChange}
											onFormChange={this.handlePersonalDetailsChange}
											history={history}
											resetAppState={this.resetAppState}
											pushWithPreservedQuery={this.pushWithPreservedQuery}
											redirectToDefaultPath={this.redirectToDefaultPath}
										/>
									)}
								/>
								<Route
									path="/payment"
									render={({ history }) => (
										<BookingPayment
											prepaymentRequired={this.state.prepaymentRequired}
											prepaymentUrl={this.state.prepaymentUrl}
											history={history}
										/>
									)}
								/>
								<Route
									path="/booked"
									render={({ history }) => (
										<BookingComplete
											locationUuid={this.state.locationUuid}
											clinicUuid={this.state.clinicUuid}
											serviceId={this.state.serviceId}
											appointmentType={this.state.appointmentType}
											analyticsAvailable={this.state.analyticsAvailable}
											isGa4={this.state.isGa4}
											facebookPixelAvailable={this.state.facebookPixelAvailable}
											tagManagerPixelAvailable={this.state.tagManagerPixelAvailable}
											website={this.state.website}
											history={history}
											onStepChange={this.handleStepChange}
											resetAppState={this.resetAppState}
										/>
									)}
								/>
								<Route
									path="/prepayment"
									render={({ history }) => (
										<PaidBookingComplete
											prepaymentUuid={this.state.prepaymentUuid}
											clinicUuid={this.state.clinicUuid}
											setBookedState={this.setBookedState}
										/>
									)}
								/>
								<Route
									path="/canceled"
									render={({ history }) => (
										<CancellationComplete
											clinicUuid={this.state.clinicUuid}
											prepaymentAmount={this.state.prepaymentAmount}
											refundAmount={this.state.refundAmount}
											refundOption={this.state.refundOption}
											appointmentToken={this.state.appointmentToken}
											isGa4={this.state.isGa4}
											history={history}
											resetAppState={this.resetAppState}
										/>
									)}
								/>
								<Route
									path="/rescheduled"
									render={({ history }) => (
										<ReschedulingComplete
											clinicUuid={this.state.clinicUuid}
											history={history}
											resetAppState={this.resetAppState}
											isGa4={this.state.isGa4}
										/>
									)}
								/>
								{/* Needs to be last, otherwise it'll match every route */}
								<Redirect from="/" to={this.getHomeRoute()} />
							</Switch>
						</Container>
					</Container>
				</Route>
			</Switch>
		)
	}

	render = () => {
		const clinicTheme = this.props.data.location ? this.props.data.location.clinic : this.props.data.clinic

		return (
			// Provide translations
			<>
				{this.state.intlLocale && (
					<LionessProvider messages={messages} locale={this.state.locale}>
						<I18NProvider locale={this.state.locale} intlLocale={this.state.intlLocale}>
							<>
								<DevPanel clinicTheme={clinicTheme} />
								{this.renderApp()}
							</>
						</I18NProvider>
					</LionessProvider>
				)}
			</>
		)
	}
}

const query = gql`
	query ($uuid: ID!, $userUuid: ID, $withUser: Boolean!) {
		clinic: clinic(uuid: $uuid) {
			colorText
			colorBackground
			colorPrimary
			colorSecondary
			locales {
				locale
				intlLocale
			}
			onlineSchedulingEnabled
		}
		location: location(uuid: $uuid) {
			clinic {
				colorText
				colorBackground
				colorPrimary
				colorSecondary
				uuid
				locales {
					locale
					intlLocale
				}
				onlineSchedulingEnabled
			}
		}
		user: user(uuid: $userUuid) @include(if: $withUser) {
			id
			displayName
		}
	}
`

// Enable hot reloading while preserving component state
export default hot(
	withRouter(
		graphql(query, {
			options: props => ({
				variables: {
					uuid: qs.parse(props.query).clinic || qs.parse(props.query).location,
					userUuid: qs.parse(props.query).user,
					withUser: !!qs.parse(props.query).user,
				},
				notifyOnNetworkStatusChange: true,
			}),
		})(App)
	)
)
