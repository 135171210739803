import * as Sentry from '@sentry/react'
import { isGoogleTagManagerError } from '../utils/isGoogleTagManagerError'

// errors already handled in 'CancelAppointment.tsx'
const graphQLErrorsWhiteList = ['Too late.', 'Already cancelled.']

// Catch all function for all unhandled exceptions. Ideally, this should never be called.
export default function catchError(error) {
	if (graphQLErrorsWhiteList.includes(error.graphQLErrors?.[0]?.message)) {
		return
	}
	const errorMessage = error || 'Uncaught exception with null error object'
	const isGtmError = isGoogleTagManagerError(error) // error from google tag manager

	// In case we are in production, show an apologetic popup and reload the page
	if (error && !isGtmError && process.env.NODE_ENV === 'production') {
		Sentry.captureException(errorMessage)
		setTimeout(() => {
			alert(
				'Oops, something went wrong. We’re going to look into it!\n' +
					'Please refresh your browser to try again.\n' +
					'\n' +
					'Oeps, er is iets misgegaan. We gaan ernaar kijken!\n' +
					'Herlaad de pagina in uw browser om het opnieuw te proberen.'
			)
		}, 2000)
	}
}
