import React from 'react'
import { Accordion, Button, Card, Divider, Icon, Label, Message } from 'semantic-ui-react'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import nl2br from 'react-nl2br'
import { T } from 'lioness'
import { groupBy, isEmpty, sortBy } from 'lodash'
import ReactGA from 'react-ga'
import ReactGA_4 from 'react-ga4'
import Fuse from 'fuse.js'
import NextButton from '../components/NextButton'
import BackButton from '../components/BackButton'
import Loader from '../components/Loader'
import OptionCard from '../components/OptionCard'
import ReactPixel from 'react-facebook-pixel'
import TagManager from 'react-gtm-module'
import { scrollToBottom, scrollToHeight } from '../utils/scrollToHeight'
import { fbTrack } from '../utils/tracking'
import { PageStep } from '../components/StepIndicator'
import OtherTreatmentsButton from '../components/OtherTreatmentsButton'
import SearchInput from '../components/SearchInput'
import ChangeLocale from '../components/ChangeLocale'
import SearchIconButton from '../components/SearchButton'
import * as Sentry from '@sentry/react'

class Home extends React.Component {
	constructor() {
		super()
		this.state = {
			gaSent: false,
			activeGroup: null,
			searchQuery: '',
			isSearchInputVisible: false,
		}
		this.whichTreatmentRef = React.createRef()
	}

	componentDidMount() {
		window.history.replaceState(null, null, `${this.props.history.location.pathname}${window.location.search}`)
		if (this.props.userId) this.props.onDoctorChange(this.props.userId)
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { data, step, onStepChange } = this.props
		if (step !== PageStep.HOME && (data.clinic || data.location)) {
			onStepChange(PageStep.HOME)
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.data?.loading) {
			return
		}

		if (nextProps.serviceId) {
			let services = nextProps.locationUuid
				? nextProps.data.location.services.map(serviceAtLocation => serviceAtLocation.service)
				: nextProps.data.clinic.services

			let selectedService = services.find(service => service.id === nextProps.serviceId)

			// Only change set the active group if there's none yet and the service has a group
			if (!this.props.activeGroup && selectedService.group) {
				this.setState({
					activeGroup: selectedService.group,
				})
			}
		}

		const internalServiceUuid = nextProps.serviceUuid || nextProps.selectedServiceUuid
		if (internalServiceUuid && !nextProps.serviceId) {
			const services = nextProps.locationUuid
				? nextProps.data?.location?.services.map(serviceAtLocation => serviceAtLocation.service)
				: nextProps.data?.clinic?.services

			const preselectedService =
				internalServiceUuid && !isEmpty(services) && services.find(service => service.uuid === internalServiceUuid)
			!!preselectedService && nextProps.onServiceChange(preselectedService.id)
		}

		// Need to save it to local state too because the App component updates once before setting openEventSent
		if (this.props.openEventSent || this.state.gaSent) {
			return
		}

		if (nextProps.analyticsAvailable) {
			const eventOptions = {
				category: 'Clinicminds Scheduler',
				action: 'Scheduler Opened',
			}
			if (nextProps.isGa4) {
				ReactGA_4.event(eventOptions)
			} else {
				ReactGA.event(eventOptions)
			}
		}

		const locationName = nextProps.data.location && nextProps.data.location.name
		if (locationName) {
			if (nextProps.analyticsAvailable) {
				const eventOptions = {
					category: 'Clinicminds Scheduler',
					action: 'Location Selected',
					label: locationName,
				}
				if (nextProps.isGa4) {
					ReactGA_4.event(eventOptions)
				} else {
					ReactGA.event(eventOptions)
				}
			}
			if (nextProps.facebookPixelAvailable) {
				fbTrack(() =>
					ReactPixel.trackCustom('LocationSelected', {
						location: locationName,
					})
				)
			}
			if (nextProps.tagManagerPixelAvailable) {
				TagManager.dataLayer({
					dataLayer: {
						event: 'LocationSelected',
						location: locationName,
					},
				})
			}
		}
		this.setState({ gaSent: true })
		this.props.onOpenEventSent()
	}

	onSearch = ({ currentTarget }) => {
		this.setState({ searchQuery: currentTarget.value })
	}

	onSearchClear = () => {
		this.setState({ searchQuery: '' })
		!this.state.searchQuery && this.setState({ isSearchInputVisible: false })
	}

	onSearchIconClick = () => {
		this.setState(state => ({ isSearchInputVisible: !state.isSearchInputVisible }))
	}

	scrollToWhichTreatment = () =>
		setTimeout(() => {
			this.whichTreatmentRef.current && scrollToHeight(this.whichTreatmentRef.current.offsetTop)
		})

	render() {
		const {
			data,
			clinicUuid,
			locationUuid,
			serviceId,
			serviceUuid,
			selectedServiceUuid,
			allowOtherServices,
			onServiceChange,
			onSelectedServiceChange,
			onClinicChange,
			onLocationChange,
			isRescheduling,
			history,
			locale,
			pushWithPreservedQuery,
			showErrorPage,
		} = this.props

		if (data?.loading) {
			return <Loader />
		}

		if (!data.location && !data.clinic) {
			// this should not happen, log to Sentry to check what's wrong
			Sentry.addBreadcrumb({
				data: {
					locationUuid,
					clinicUuid,
				},
			})
			Sentry.addBreadcrumb({
				data: { error: data.error },
			})
			Sentry.captureException('Location and clinic not defined')
			showErrorPage()
			return null
		}

		const clinic = locationUuid ? data.location.clinic : data.clinic
		const services = locationUuid
			? data.location.services.map(serviceAtLocation => serviceAtLocation.service)
			: data.clinic.services

		const internalServiceUuid = serviceUuid || selectedServiceUuid
		const preselectedService = internalServiceUuid && services.find(service => service.uuid === internalServiceUuid)

		// display error when a service is specified in an url, but it's incorrect or not linked to any user/location
		// if 'allowOtherServices' - show other services instead
		if (serviceUuid && !preselectedService && !allowOtherServices) {
			showErrorPage()
			return null
		}

		const displaySearchIcon = services?.length > 6 && !this.state.isSearchInputVisible

		const fuse = new Fuse(services, {
			keys: ['group', 'name', 'explanation'],
			minMatchCharLength: 3,
			includeScore: true,
		})

		const searchResult = fuse.search(this.state.searchQuery)
		const filteredServices = this.state.searchQuery.length > 2 ? searchResult.map(({ item }) => item) : services
		const showOtherTreatmentsButton =
			(allowOtherServices && serviceUuid && !!preselectedService) ||
			(services.length > filteredServices.length && filteredServices.length > 0)
		const disableNextButton = !serviceId || !filteredServices.some(({ id }) => id === serviceId)

		const locales = locationUuid ? data.location.locales : data.clinic.locales

		return (
			<div>
				{locales?.length > 1 && <ChangeLocale clinicLocales={locales} currentLocale={locale} />}

				{/* TODO: Maybe include some sort of "info" icon/header? */}
				{isRescheduling || (
					<div>
						<Message info>
							{/* <Message.Header>
                {clinic.brandName}
              </Message.Header> */}
							<p>{nl2br(clinic.textStart)}</p>
						</Message>
						<Divider hidden />
					</div>
				)}
				{serviceUuid && preselectedService ? (
					<Card color="green" fluid>
						<Card.Content>
							<Card.Header style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
								{preselectedService.name}
							</Card.Header>
							<Card.Description>{nl2br(preselectedService.explanation)}</Card.Description>
						</Card.Content>
					</Card>
				) : (
					<div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								flexWrap: 'wrap',
								minHeight: '38px',
								alignItems: 'center',
								marginBottom: '20px',
							}}
						>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									flex: '1',
									flexBasis: 'auto',
								}}
							>
								<h3 ref={this.whichTreatmentRef} style={{ margin: '6px 15px 6px 0', padding: '5px 0' }}>
									<T>Select an option</T>
								</h3>
								<SearchIconButton onClick={this.onSearchIconClick} displaySearchIcon={displaySearchIcon} />
							</div>
							{this.state.isSearchInputVisible && (
								<SearchInput onSearch={this.onSearch} value={this.state.searchQuery} onClear={this.onSearchClear} />
							)}
						</div>
						<Divider hidden />
						{filteredServices.length > 0 ? (
							<>
								<Card.Group itemsPerRow={3} stackable data-testid="service-cards">
									{sortBy(filteredServices, o => o.orderPosition)
										.filter(service => service.group === null)
										.map(service => (
											<OptionCard
												key={service.id}
												title={service.name}
												details={nl2br(service.explanation)}
												selected={serviceId === service.id}
												onClick={() => {
													scrollToBottom()
													onServiceChange(service.id)
												}}
												dataTestId="service-card"
											/>
										))}
								</Card.Group>
								{filteredServices.some(service => service.group) &&
									filteredServices.some(service => !service.group) && <Divider hidden />}
								<Accordion fluid>
									{Object.entries(
										groupBy(
											sortBy(
												filteredServices.filter(service => service.group !== null),
												s => s.group
											),
											'group'
										)
									).map(([group, filteredServices]) => (
										<React.Fragment key={group}>
											<Accordion.Title
												active={this.state.activeGroup === group}
												index={group}
												onClick={() => {
													const newGroup = this.state.activeGroup === group ? null : group
													this.setState({ activeGroup: newGroup })
												}}
											>
												<Label
													size="big"
													color={this.state.activeGroup === group ? 'grey' : undefined}
													style={{
														width: '100%',
														display: 'flex',
														alignItems: 'center',
													}}
												>
													<p style={{ flex: '0 0 25px', margin: 0, padding: 0 }}>
														<Icon name="dropdown" />
													</p>
													<p style={{ margin: 0, padding: 0 }}>{group}</p>
												</Label>
											</Accordion.Title>
											<Accordion.Content active={this.state.activeGroup === group}>
												<Card.Group itemsPerRow={3} stackable>
													{sortBy(filteredServices, o => o.orderPosition).map(service => (
														<OptionCard
															key={service.id}
															title={service.name}
															details={nl2br(service.explanation)}
															selected={serviceId === service.id}
															onClick={() => {
																scrollToBottom()
																onServiceChange(service.id)
															}}
														/>
													))}
												</Card.Group>
											</Accordion.Content>
										</React.Fragment>
									))}
								</Accordion>
							</>
						) : services.length === 0 ? (
							<p>
								<T>This booking cannot be made online. Please contact the clinic to book an appointment.</T>
							</p>
						) : (
							<p>
								<T
									message="No treatments were found. Please try another search term, or {{ link: view all treatments }}."
									link={
										<button
											className="internalLink"
											onClick={() => {
												this.setState({ searchQuery: '' })
												this.scrollToWhichTreatment()
											}}
										/>
									}
								/>
							</p>
						)}
					</div>
				)}
				<Divider hidden />
				{/* Careful, floated button groups need clearfix (it is currently on app container) */}
				<Button.Group floated="right">
					<NextButton
						disabled={disableNextButton}
						onClick={() => {
							const serviceName = services.find(s => s.id === this.props.serviceId).name

							if (this.props.analyticsAvailable) {
								const eventOptions = {
									category: 'Clinicminds Scheduler',
									action: 'Service Selected',
									label: serviceName,
								}
								if (this.props.isGa4) {
									ReactGA_4.event(eventOptions)
								} else {
									ReactGA.event(eventOptions)
								}
							}
							/*
							if (this.props.facebookPixelAvailable) {
								fbTrack(() =>
									ReactPixel.trackCustom('ServiceSelected', {
										service: serviceName,
									})
								)
							}
							*/
							if (this.props.tagManagerPixelAvailable) {
								TagManager.dataLayer({
									dataLayer: {
										event: 'ServiceSelected',
										service: serviceName,
									},
								})
							}

							onClinicChange(clinic.uuid)

							// Why exactly are we doing this? Looks useless, if there's no clinic id, there must already be a location id. 🤔
							if (!clinicUuid) {
								onLocationChange(data.location.uuid)
							}

							pushWithPreservedQuery('/appointment-types')
						}}
					/>
				</Button.Group>
				{showOtherTreatmentsButton && (
					<Button.Group floated="left">
						<OtherTreatmentsButton
							onClick={() => {
								this.setState({ searchQuery: '' })
								onSelectedServiceChange(serviceUuid)
								this.scrollToWhichTreatment()
							}}
						/>
					</Button.Group>
				)}
				{isRescheduling && (
					<Button.Group floated="left">
						<BackButton
							onClick={() => {
								// No need to rest state here because it is going to be replaced anyway
								history.goBack()
							}}
						/>
					</Button.Group>
				)}
			</div>
		)
	}
}

Home.propTypes = {}

const query = gql`
	query ($uuid: ID!, $userId: Int) {
		clinic: clinic(uuid: $uuid) {
			id
			uuid
			brandName
			services(userId: $userId) {
				id
				uuid
				name
				group
				explanation
				orderPosition
			}
			textStart
			locales {
				localeId
				locale
				language
			}
		}
		location: location(uuid: $uuid) {
			uuid
			name
			services(userId: $userId) {
				service {
					id
					uuid
					name
					group
					explanation
					orderPosition
				}
			}
			clinic {
				uuid
				brandName
				textStart
				locales {
					localeId
					locale
					language
				}
			}
		}
	}
`

export default graphql(query, {
	options: props => ({
		variables: { uuid: props.locationUuid || props.clinicUuid, userId: props.userId },
		notifyOnNetworkStatusChange: true,
	}),
})(Home)
